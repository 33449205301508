import React from "react";
import { Image } from "@chakra-ui/react";
import { motion } from "framer-motion";

function Shuriken() {
  const MotionImage = motion(Image);
  return (
    <>
      <MotionImage
        src="/images/shuriken.png"
        width="50px"
        pb={5}
        initial={{
          x: 1000,
        }}
        animate={{
          rotate: -360,
          x: 0,
        }}
        transition={{
          duration: 1.5,
        }}
      />
    </>
  );
}

export default Shuriken;

import React from 'react';
import { Image, Flex } from '@chakra-ui/react';

const Logo = () => {
  return (
    <Flex align="center">
      <Image
        src="images/pixelninjas_icon.svg"
        width={30}
        alt="Pixelninjas Icon"
      />
      <Image
        src="images/pixelninjas_text.svg"
        width={100}
        pl={2}
        alt="Pixelninjas Wordmark"
      />
    </Flex>
  );
};
export default Logo;

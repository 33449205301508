import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Layout from '../components/layouts/main'
import Home from './Home'

function _app() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
        </Route>
      </Routes>
    </>
  )
}

export default _app

import React from 'react'
import { Flex, Box, Container, Text, Stack, Link } from '@chakra-ui/react'
import Logo from '../components/Logo'
import Shuriken from '../components/Shuriken'

function Home() {
  return (
    <Flex
      h="100vh"
      mx="auto"
      align="center"
      justify="center"
      px={{ base: '5', md: '5' }}
    >
      <Box>
        <Container
          background="white"
          p={{ base: 10, md: 20 }}
          boxShadow="lg"
          rounded="lg"
        >
          <Flex overflow="hidden">
            <Logo />
            <Shuriken />
          </Flex>
          <Text my={5}>
            My name is <b>Jae</b>, and I've been a developer and designer for
            over 15 years. Work has taken me to interesting directions and I
            consider myself fortunate to have collaborated on a multitude of
            projects, from branding to app development spanning a wide range of
            disciplines.
          </Text>
          <Text mb={5}>
            Tech is not just my passion but my way of life. I love meeting new
            people, sharing ideas and learning from those who are enthusiastic
            about programming and design.
          </Text>

          <Stack spacing={4} direction="row">
            <Link isExternal href="https://dribbble.com/pixelninjas">
              <Text fontWeight="bold" textDecoration="underline">
                Dribbble
              </Text>
            </Link>
            <Link href="https://github.com/pixeljae" isExternal>
              <Text fontWeight="bold" textDecoration="underline">
                Github
              </Text>
            </Link>
            <Link href="mailto:jae@pixelninjas.ca" isExternal>
              <Text fontWeight="bold" textDecoration="underline">
                Email
              </Text>
            </Link>
          </Stack>
        </Container>
      </Box>
    </Flex>
  )
}

export default Home

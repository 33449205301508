import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const styles = {
  global: (props) => ({
    body: {
      fontFamily: "body",
      color: mode("gray.800", "whiteAlpha.900")(props),
      bg: mode("white", "gray.800")(props),
      lineHeight: "base",
    },
    "*::placeholder": {
      color: mode("gray.400", "whiteAlpha.400")(props),
    },
    "*, *::before, &::after": {
      borderColor: mode("gray.200", "whiteAlpha.300")(props),
      wordWrap: "break-word",
    },
  }),
};

const components = {
  Button: {
    variants: {
      "with-shadow": {
        bg: "green.400",
        themcolor: "white",
        boxShadow: "0 0 2px 2px #efdfde",
      },
      solid: {
        bg: "black",
        color: "white",
      },
    },
  },
  Heading: {
    variants: {
      h1: {
        fontSize: "6xl",
        fontWeight: "bold",
      },
      h2: {
        fontSize: "3xl",
        fontWeight: "bold",
      },
    },
  },
  Text: {
    variants: {
      subtitle: {
        fontSize: "xl",
        fontWeight: "medium",
      },
      body: {
        fontSize: "md",
        fontWeight: "medium",
      },
    },
  },
};

const colors = {
  primary: "#000000",
};

const config = {
  useSystemColorMode: false,
};

const theme = extendTheme({ styles, components, config, colors });
export default theme;

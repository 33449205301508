import React from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@chakra-ui/react";

function main() {
  return (
    <Box bgGradient="linear(to-tl, gray.400, white)">
      <Outlet />
    </Box>
  );
}

export default main;
